import styled from '@emotion/styled';

import { screen } from '@/components/common/breakpoints';
import { Button } from '@/components/common/Buttons';
import { Image } from '@/components/common/image';
import { Paragraph } from '@/components/common/MarkUp';
import { PortableText } from '@/components/common/utils';
import { blockUrlWithAudience } from '@/lib/utils';

import type {
  SanityAudienceType,
  SanityExplainerCardType,
} from '@/types/sanity';
import type { BlockWithAudience, TrackingTagType } from '@/types/shared';

export interface ExplainerCardProps
  extends Omit<SanityExplainerCardType, 'CTA'>,
    BlockWithAudience {
  CTA?: {
    _type: 'CTA';
    text?: string;
    audienceRef?: SanityAudienceType;
    accessibleLabel?: string;
    noAudienceToggle?: boolean;
    url?: string;
    trackingTag?: TrackingTagType;
  };
}

const ExplainerCard = ({
  title,
  body,
  image,
  CTA,
  pageAudience,
  isAudienceSwitcherEnabled,
}: ExplainerCardProps) => {
  return (
    <Container>
      {image?.asset && (
        <ImageContainer>
          <Image {...image} objectFit="contain" height={56} width={56} />
        </ImageContainer>
      )}
      {title && <Title size="heading-sm">{title} </Title>}
      <PortableText
        value={body}
        audienceConfig={{ pageAudience, isAudienceSwitcherEnabled }}
      />
      {!!CTA.text && (
        <ButtonContainer>
          <Button
            variant="solid-light"
            href={blockUrlWithAudience(
              CTA.url,
              CTA.audienceRef,
              pageAudience,
              CTA.noAudienceToggle,
              isAudienceSwitcherEnabled,
            )}
          >
            {CTA.text}
          </Button>
        </ButtonContainer>
      )}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: var(--spacing-small);

  ${screen.sm} {
    padding: var(--spacing-250);
  }
`;

const Title = styled(Paragraph)`
  padding-top: var(--spacing-x-small);
  padding-bottom: var(--spacing-xxx-small);
  ${screen.sm} {
    padding-top: var(--spacing-small);
  }
`;
const ImageContainer = styled.div`
  margin-bottom: var(--spacing-medium);
  > img {
    height: 56px;
    width: 56px;
  }
`;

const ButtonContainer = styled.div`
  padding-top: var(--spacing-x-small);
  display: flex;
  height: 100%;
  align-items: flex-end;
  ${screen.sm} {
    padding-top: var(--spacing-small);
  }
`;

export { ExplainerCard };

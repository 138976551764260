import styled from '@emotion/styled';

import { screen } from '@/components/common/breakpoints';
import { CaptionHeadingDescription } from '@/components/common/CaptionHeadingDescription';
import { Image } from '@/components/common/image';
import { HeadingLevel, Paragraph } from '@/components/common/MarkUp';

import type { BlockWithAudience } from '@/types/shared';
import type {
  SanityBlock,
  SanityImageAsset,
  SanityKeyed,
  SanityReference,
} from 'sanity-codegen';

export interface ExtendedImageStatProps extends BlockWithAudience {
  _type?: string;
  image?: {
    _type: 'image';
    asset: SanityReference<SanityImageAsset>;
    alternateText?: string;
    isDecorativeImage?: boolean;
  };
  columnHeading?: Array<SanityKeyed<SanityBlock>>;
  body: Array<SanityKeyed<SanityBlock>>;
  statHeading?: string;
  statBody?: string;
}

const ExtendedImageStat = ({
  image,
  columnHeading,
  body,
  statHeading,
  statBody,
  pageAudience,
  isAudienceSwitcherEnabled,
}: ExtendedImageStatProps) => {
  return (
    <ColumnWrapper>
      <ImageWrapper>
        <Image {...image} />
      </ImageWrapper>
      <HeadingLevel>
        <CaptionHeadingDescription
          heading={columnHeading}
          description={body}
          defaultHeadingSize="subheading-sm"
          padding={{ description: 'small', descriptionMobile: 'small' }}
          desktopAlignment="center"
          mobileAlignment="center"
          pageAudience={pageAudience}
          isAudienceSwitcherEnabled={isAudienceSwitcherEnabled}
        />
      </HeadingLevel>

      <StatWrapper>
        <StatHeading size="subheading-md">{statHeading}</StatHeading>
        <StatBody size="body-lg">{statBody}</StatBody>
      </StatWrapper>
    </ColumnWrapper>
  );
};

const ColumnWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 0 0;
  gap: var(--spacing-small, 16px);
  align-self: stretch;
  position: relative;
  ::before {
    content: '';
    height: 1px;
    display: block;
    width: 169px;
    background: var(--border-warm-subtle);
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
  }

  &:first-of-type {
    padding-top: var(--spacing-small);
  }

  &:last-of-type {
    ::before {
      display: none;
    }
  }

  ${screen.sm} {
    display: grid;
    grid-template-rows: auto 1fr 1fr;
    gap: var(--spacing-small, 16px);

    &:first-of-type {
      padding-top: 0;
    }

    ::before {
      display: none;
    }
  }
`;

const ImageWrapper = styled.div`
  padding-top: var(--spacing-400);
  width: 100%;
  > img {
    width: 72px;
  }
`;

const StatWrapper = styled.div`
  padding-bottom: var(--spacing-400);
  width: 100%;
  align-self: flex-start;

  ${screen.sm} {
    padding-top: var(--spacing-small);
    padding-bottom: 0;
    position: relative;
    ::before {
      content: '';
      height: 1px;
      display: block;
      width: 100%;
      background: var(--border-warm-subtle);
      position: absolute;
      top: 0;
      left: 0;
    }
  }
`;

const StatHeading = styled(Paragraph)`
  margin-bottom: var(--spacing-xxx-small);
  color: var(--text-action);
`;

const StatBody = styled(Paragraph)`
  color: var(--text-cool-base);
`;

export { ExtendedImageStat };

import styled from '@emotion/styled';
import Link from 'next/link';

import { screen } from '@/components/common/breakpoints';
import { Image } from '@/components/common/image';
import { Heading } from '@/components/common/MarkUp';
import { PortableText } from '@/components/common/utils';
import { blockUrlWithAudience } from '@/lib/utils';

import type {
  SanityAudienceType,
  SanitySuccessStoryCardType,
} from '@/types/sanity';
import type { BlockWithAudience } from '@/types/shared';

export interface SuccessStoryCardProps
  extends Omit<SanitySuccessStoryCardType, 'audienceRef'>,
    BlockWithAudience {
  audienceRef?: SanityAudienceType;
}

const SuccessStoryCard = ({
  title,
  image,
  logo,
  textOverlay,
  url,
  buttonAccessibleLabel,
  trackingTag,
  audienceRef,
  noAudienceToggle,
  pageAudience,
  isAudienceSwitcherEnabled,
}: SuccessStoryCardProps) => {
  url = blockUrlWithAudience(
    url,
    audienceRef,
    pageAudience,
    noAudienceToggle,
    isAudienceSwitcherEnabled,
  );
  return (
    <CardContainer
      href={url}
      className={`${
        trackingTag?.blockName
          ? `tracking-${trackingTag.blockName}__${trackingTag.blockAction}`
          : ''
      }`}
      aria-label={buttonAccessibleLabel}
    >
      <ImageContainer>
        <Image objectFit="cover" {...image} alt="" />
        {logo && (
          <LogoContainer>
            <Logo objectFit="contain" {...logo} alt="" />
          </LogoContainer>
        )}
        {textOverlay && (
          <TextOverlay>
            <PortableText
              value={textOverlay}
              audienceConfig={{ pageAudience, isAudienceSwitcherEnabled }}
            />
          </TextOverlay>
        )}
        <ImageGradientOverlay />
      </ImageContainer>
      <Title size="heading-sm" elementType="paragraph">
        {title}
      </Title>
    </CardContainer>
  );
};

const CardContainer = styled(Link)`
  border: 1px solid var(--colour-border-subtle);
  border-radius: 12px;
  display: block;
  text-decoration: none;
  color: unset;
  background: var(--background-subtle);

  transition: transform 0.3s;
  will-change: transform;

  &:hover {
    transform: scale(1.03);
  }
`;

const ImageContainer = styled.div`
  position: relative;
  overflow: hidden;
  border-radius: 12px 12px 0 0;
  > img {
    height: 160px;
  }

  ${screen.sm} {
    > img {
      height: 192px;
    }
  }
  ${screen.lg} {
    > img {
      height: 292px;
    }
  }
`;

const ImageGradientOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  background: linear-gradient(
    180deg,
    rgba(27, 27, 65, 0.4) 0%,
    rgba(27, 27, 65, 0) 37.75%
  );
  width: 100%;
  height: 100%;
  z-index: 1;
  border-radius: 12px 12px 0 0;
`;

const TextOverlay = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  margin: 0 var(--spacing-small) var(--spacing-small) var(--spacing-small);
  z-index: 2;
  color: var(--text-inverse-strong);
  padding: var(--spacing-xx-small) var(--spacing-x-small);
  border-radius: 8px;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    background: rgba(0, 0, 0, 0.48);
    border-radius: 8px;
  }
  ${screen.sm} {
    margin: 0 var(--spacing-250) var(--spacing-250) var(--spacing-250);
  }
`;

const LogoContainer = styled.div`
  position: absolute;
  top: var(--spacing-small);
  left: var(--spacing-small);
  width: 100%;
  z-index: 2;

  img {
    height: 44px;
    width: 100%;
    object-position: left;
  }

  ${screen.sm} {
    top: var(--spacing-250);
    left: var(--spacing-250);
  }
`;

const Title = styled(Heading)`
  margin: var(--spacing-x-small) var(--spacing-small) var(--spacing-small);

  ${screen.sm} {
    margin: var(--spacing-small) var(--spacing-250) var(--spacing-250);
  }
`;
const Logo = styled(Image)``;

export { SuccessStoryCard };

import styled from '@emotion/styled';

import { screen } from '@/components/common/breakpoints';
import { CaptionHeadingDescription } from '@/components/common/CaptionHeadingDescription';
import { HeadingLevel } from '@/components/common/MarkUp';

import type { HeadingSanityBlockType } from '@/components/common/Heading';
import type { BlockWithAudience } from '@/types/shared';
import type { SanityBlock, SanityKeyed } from 'sanity-codegen';

export interface ParagraphProps extends BlockWithAudience {
  _type?: string;
  heading?: HeadingSanityBlockType;
  body: Array<SanityKeyed<SanityBlock>>;
  verticalAlignDesktop?: 'start' | 'center' | 'end';
  verticalAlignMobile?: 'start' | 'center' | 'end';
}

const Paragraph = ({
  heading,
  body,
  verticalAlignDesktop = 'start',
  verticalAlignMobile = 'start',
  pageAudience,
  isAudienceSwitcherEnabled,
}: ParagraphProps) => {
  return (
    <ParagraphContainer
      verticalAlignDesktop={verticalAlignDesktop}
      verticalAlignMobile={verticalAlignMobile}
    >
      <HeadingLevel>
        <StyledCaptionHeadingDescription
          heading={heading}
          description={body}
          defaultHeadingSize="subheading-sm"
          padding={{ description: 'xxx-small', descriptionMobile: 'xxx-small' }}
          pageAudience={pageAudience}
          isAudienceSwitcherEnabled={isAudienceSwitcherEnabled}
        />
      </HeadingLevel>
    </ParagraphContainer>
  );
};

const ParagraphContainer = styled.div<{
  verticalAlignDesktop: 'start' | 'center' | 'end';
  verticalAlignMobile: 'start' | 'center' | 'end';
}>`
  display: flex;
  flex-direction: column;
  text-align: ${({ verticalAlignMobile }) => `${verticalAlignMobile}`};
  align-self: ${({ verticalAlignMobile }) => `${verticalAlignMobile}`};

  ${screen.sm} {
    text-align: ${({ verticalAlignDesktop }) => `${verticalAlignDesktop}`};
    align-self: ${({ verticalAlignDesktop }) => `${verticalAlignDesktop}`};
  }
`;

const StyledCaptionHeadingDescription = styled(CaptionHeadingDescription)`
  margin-bottom: var(--spacing-xx-small);
`;

export { Paragraph };
